.LoginPage {
  .bg-login-image {
    background: url("./jungle-login.png");
    background-position: center;
    background-size: cover;
    min-height: 500px;
  }

  .form-control {
    padding: 1.5rem 1rem;
  }

  .btn-user {
    border-radius: 10rem;
    padding: 0.75rem 1rem;
  }

  .btn-google {
    color: #fff;
    background-color: #ea4335;
    border-color: #fff;
  }
}
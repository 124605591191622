$header-height: 48px;
$sidebar-normal-width: 75px;
$sidebar-expanded-width: 275px;
$sidebar-background-color: #2E7619;

$gold: #ad9c03;
$purple: #8b03ad;
$light-green: #82bd14;
$green: #1cb608;
$forest-green: #01850c;
$electric-blue: #027dd4;
$navy-blue: #123456;
$ochre: #e05b3a;

$cities: (
  bordeaux: #D82142,
  lille: #7A0037,
  lyon: #43B82A,
  montpellier: #1098F7,
  paris: #1A7DC7,
  toulouse: #6D68BA,
);

$statuses: (
  draft: $gold,
  ready: $gold,
  stock: $purple,
  assessed: $light-green,
  booked: $light-green,
  pending: $light-green,
  scheduled: $green,
  active: $forest-green,
  occupied: $forest-green,
  checked-in: $forest-green,
  ongoing: $forest-green,
  available: $electric-blue,
  done: $electric-blue,
  archived: $navy-blue,
  cancelled: $ochre,
  maintenance: $ochre,
  checked-out: $ochre,
);

$teams: (
  immo: $gold,
  dandq: $light-green,
  sales: $green,
  happi: $forest-green,
  prop: $electric-blue,
  acco: $navy-blue,
);
